<template>
  <div>
  <div v-if="value != null" >

  <b-iconstack :font-scale="fontScale" @click="onClick" >
      <b-icon stacked :icon="linkIcon" :scale="value ? 1 : 0.8" :variant="value ? onVariant: offVariant"></b-icon>
      <b-icon stacked icon="slash-circle" v-show="!  value" :variant="offVariantBorder"></b-icon>
    </b-iconstack>
      </div>
  </div>
</template>

<script>

export default{
  name:"IconCategory",
  props:{
    value: { type:Boolean, required:true },
    size: { type:String, default:''},
    onVariant: { type:String, default:'success'},
    offVariant: { type:String, default:'danger' },
    offVariantBorder: { type:String, default: 'danger'},
    borderVariant: { type:String, required:false},
    contentVariant: { type: String, required:false},
    display45deg: { type:Boolean, default: false},
  },
  computed:{
    linkIcon(){
      return this.display45deg ? "link45dev" : "link";
    },
    borderIcon(){
      return "circle";
    },

    fontScale(){
      switch(this.size)
      {
        case "xsm": return 1;
        case "sm": return 2;
        case "lg": return 4;
        case "xl": return 5;
        case "xxl": return 6;
        default: return 3;
      }
    },
    bVariant(){
      return (this.borderVariant != null) ? this.borderVariant : this.variant;
    },
    cVariant(){
      return (this.contentVariant != null) ? this.contentVariant : this.variant;
    },
  },
  methods:{
    onClick(){
      this.$emit('click');
    }
  }
}
</script>

<style scoped>
  .expert { background-color:gold; }
  .inter { background-color:silver; }
  .novice { background-color: #bf8970 }
</style>

