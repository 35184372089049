<template>
    <div>
      <competitions-list
          :items="competitions"
          :canDelete="userCanDelete"
          :confirmBeforeDeleting="true"
          :canLock="userHasRole(Roles.Admin)"
          :canUnlock="userHasRole(Roles.Admin)"
          @select="selectCompetition"
          @remove="deleteCompetition"
      >
      </competitions-list>
      <div v-if="competitions == null || competitions.length == 0">
        Aucune compétition en cours vous concernant n'a été trouvée !
      </div>
    </div>
</template>

<script>
import SecurityMap from "@/Constants/securityMap.js";
import CompetitionsList from '@/components/CompetitionsList'
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';

export default {
    components: { CompetitionsList },
    data(){
      return {
        competitions: [],
        userCanDelete: false,
      };
    },
    computed:{
        securityMapOperations(){ return SecurityMap.Operations; },
    },
    methods:{
        async refresh(){
          this.userCanDelete = this.userCanNew(this.securityMapOperations.deleteCompetition);
          this.competitions = await CompetitionsConfigurationHelper.getUserRunningCompetitionsAsync(this.isTestMode);
        },
        async selectCompetition(competition)
        {
          await this.showLoader();
          //await CompetitionsConfigurationHelper.refreshCompetition(competition.id);
          this.setCurrentCompetition_id(competition.id);
          var elementsToCorrect = CompetitionsConfigurationHelper.missingRequirementsBeforeRun(competition.id);
          if(elementsToCorrect.length > 0){
              this.$router.push({ name: 'CompetitionEdit', params: { id: competition.id }});
          } else {
              this.$router.push({ name: 'CompetitionEditOrRun', params: { id: competition.id }} );
          }
        },
        async deleteCompetition(competition)
        {
          await this.showSaver();
          try{
            var result = CompetitionsConfigurationHelper.deleteCompetition(competition.id);
            this.toastAsyncSaved(result, 'Compétition supprimée');
          }catch(error)
          {
            console.log('deleteCometition Error', error);
            this.toastSaveFailure(error);
          }
          this.$hideSader();
        }
    },
    async mounted(){
      await this.showLoader();
      await this.refresh();
      await this.checkSynchro();
      this.$hideLoader();
    }
}
</script>
